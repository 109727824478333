import {Component, inject, OnInit} from '@angular/core'
import {filter, switchMap} from 'rxjs'
import {ApplicationService} from '../../../services/application.service'
import {
  IBorgoApplicationResult,
  IBorgoCreditBreakdownRequirements,
  IBorgoInterestListPrice,
  IOfferingPayload,
  TApplicationCosts,
  TApplicationLoanBinding,
  TBorgoRuleFramework
} from 'sparbanken-syd-borgo'
import {DisclaimerService} from '../../../services/disclaimer.service'
import {UserService} from '../../../services/user.service'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {ApplicationLoanBindingMap} from '@sparbanken-syd/borgo-helpers'
import {sortOriginal} from '../../../application/helpers'
import {startWith} from 'rxjs/operators'
import {MatDialog} from '@angular/material/dialog'
import {IWaitComponentData, WaitComponent} from '../../../common/dialogs/wait/wait.component'
import {Alternative} from './alternative/alternative'

@Component({
  selector: 'spb-offer',
  templateUrl: './offer.component.html',
  styleUrl: './offer.component.scss'
})
export class OfferComponent implements OnInit {

  protected loading = true

  /**
   * This always represent the loan amount
   */
  protected loan: number = 0
  protected discount: number = 0

  protected alternativePossible: boolean = false

  /**
   * This is the additional amount of amortization w/ alternativ
   * regel. This + alternativeRuleMonthlyAmortization should
   * equal alternativeRuleIncreaseMonthlyAmortization
   *
   * We calculate this value as increase / 120
   */
  protected alternativeRuleAmortization: number = 0

  /**
   * The amount that is currently paid on Alternativregel
   * amortization. Must be added to the selected value and
   * never together less than alternativeRuleIncreaseMonthlyAmortization
   * @protected
   */
  protected alternativeRuleMonthlyAmortization: number = 0

  /**
   * The _total amount_ of amortization if alternative rule is selected.
   * this is what is passed (as a minimum) to the order
   */
  protected alternativeRuleIncreaseMonthlyAmortization: number = 0
  /**
   * The total sum of loans on Alternativregel. This
   * is what we send in the offer if Alternativregel is selected
   *
   * AND there are existing loans on alternativregel. Else we send
   * the loan amount
   */
  protected alternativeRuleIncreaseCreditAmount: number = 0

  /**
   * The current amortization on existing loans.
   */
  protected amortizationBasisTotalMonthlyAmortization: number = 0

  public currentRuleFramework: TBorgoRuleFramework = 'NONE'

  public alternativeCtrl = new FormControl<boolean | null>(null)

  public alternative: Alternative = new Alternative({} as any)

  protected form = new FormGroup({
    binding: new FormControl<TApplicationLoanBinding | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    amortization: new FormControl<number | null>(null, {nonNullable: true, validators: [Validators.required]})
  })

  protected bindings = ApplicationLoanBindingMap
  protected readonly sortOriginal = sortOriginal
  /**
   * The cost of "inteckning", "digitalt skuldebrev"
   */
  protected stampDuty: number = 0
  protected stamDutyFee: number = 0

  protected interestRates: IBorgoInterestListPrice[] = []

  protected caseId: string = ''
  protected applicationId: string = ''

  protected declinesOffer?: boolean

  protected disclaimer = ''
  private disclaimerService = inject(DisclaimerService)

  private applicationService = inject(ApplicationService)

  private userService = inject(UserService)

  private actualMortgage: number | null = null

  private requirements: IBorgoCreditBreakdownRequirements | null = null

  private dialog = inject(MatDialog)
  private offer: IOfferingPayload = {
    accept: false,
    rule: 'NONE',
    items: []
  }
  private feeMap = new Map<TApplicationCosts, any>([
    ['MORTGAGE_DEED_STAMP_DUTY', 'stampDuty'],
    ['MORTGAGE_DEED_AUTHORITY_HANDLING_FEE', 'stamDutyFee']
  ])

  public ngOnInit(): void {
    this.disclaimerService.showDisclaimer()
      .subscribe(disclaimer => this.disclaimer = disclaimer
      )

    this.applicationService.currentApplicationResult$.pipe(
      filter(Boolean),
      switchMap((a) => {
        // Do not go here if you are not sure...
        this.applicationId = a.applicationId
        this.caseId = a.caseId
        this.requirements = a.creditBreakdownRequirements
        this.setFees(a)
        return this.userService.getInterests()
      }),
      switchMap((rates) => {
        this.interestRates = rates
        return this.applicationService.currentSaveData$
      }),
      filter(Boolean)
    )
      .subscribe({
        next: (a) => {

          this.discount = a.interestDeviation
          this.actualMortgage = a.actualMortgage
          this.interestRates = this.interestRates.map(i => {
            i.interestRate = (Number.parseFloat(i.interestRate) - this.discount) + ''
            return i
          })
          this.createLoans(this.requirements!)
          this.declinesOffer = a.declinesOffer
          this.loading = false
        }
      })

    this.alternativeCtrl.valueChanges.pipe(
      startWith(null)
    ).subscribe({
      next: (v) => {
        if (v) {
          this.form.controls.amortization.setValidators(
            [Validators.required, Validators.min(this.alternative.alternativeMortgage)])
          this.form.controls.amortization.setValue(
            this.alternative.alternativeMortgage)
        } else {
          this.form.controls.amortization.setValidators(
            [Validators.required, Validators.min(this.alternative.ruleMortgage)])
          this.form.controls.amortization.setValue(
            this.alternative.ruleMortgage)
        }
        this.setOfferData(false)
      }
    })
  }


  public sendOffer(accept: boolean): void {
    const ref = this.dialog.open<WaitComponent, IWaitComponentData, void>(WaitComponent, {
      data: {
        title: '',
        text: 'Vi sparar dina valda villkor.'
      }
    })
    this.setOfferData(accept)

    // First create the offer
    this.userService.setOfferResponse(this.caseId, this.applicationId, this.offer)
      .pipe(
        // Then update the application
        switchMap(() => {
          return this.userService.getApplication(this.caseId, this.applicationId)
        })
      )
      .subscribe({
        next: () => ref.close()
      })
  }

  public declineOffer(): void {
    this.declinesOffer = true
    this.userService.setSavedData(this.applicationId, {declinesOffer: true})
      .subscribe()
  }

  /**
   * Manipulates our offer to get the correct values set
   * @param accept - Almost always true
   */
  private setOfferData(accept: boolean): void {
    this.offer.items.forEach(offer => {
      this.offer.accept = accept
      // The amounts are set in the control, different if alternative rule
      // Default is just the value in the control
      offer.monthlyAmortization = this.form.controls.amortization.value as number

      if (this.alternativeCtrl.value) {
        offer.alternativeRule = true
        // If amount is set on alternative rule, use that value, otherwise use the increase amount??
        offer.amount = this.alternativeRuleIncreaseCreditAmount
        this.offer.rule = this.currentRuleFramework === 'NONE' ? 'NONE' : 'LTV_LTI'
        offer.monthlyAmortization = this.alternative.alternativeMortgage
      } else {
        // If not by alternative rule, all new loans are LTV_LTI (skärpt amorteringskrav)
        this.offer.rule = 'LTV_LTI'
      }
      // Only if values are select we come here

      offer.rateFixationPeriod = this.form.controls.binding.value as TApplicationLoanBinding
      offer.interestRateDiscount = this.discount + ''
    })
  }

  private createLoans(reqs: IBorgoCreditBreakdownRequirements): any {
    this.loan = reqs.increaseCreditAmount
    // Set default under current rule framework
    this.alternative = new Alternative(reqs, this.actualMortgage)

    this.form.controls.amortization.setValue(this.alternative.ruleMortgage)
    this.form.controls.amortization.setValidators(
      [Validators.required, Validators.min(this.alternative.ruleMortgage)])

    // Total
    this.alternativeRuleIncreaseMonthlyAmortization = reqs.alternativeRuleIncreaseMonthlyAmortization

    this.alternativeRuleIncreaseCreditAmount = reqs.alternativeRuleIncreaseCreditAmount

    this.amortizationBasisTotalMonthlyAmortization = reqs.amortizationBasisTotalMonthlyAmortization

    this.currentRuleFramework = reqs.currentRuleFramework

    this.offer.rule = reqs.currentRuleFramework ?? 'NONE'
    this.offer.items = [{
      order: 1,
      amount: reqs.increaseCreditAmount,
      rateFixationPeriod: '3',
      monthlyAmortization: reqs.strictRuleMonthlyAmortization,
      interestRateDiscount: '0',
      interestRateDiscountInMonths: '12',
      alternativeRule: false,
      // I think this is set by Borgo
      creditBreakdownId: '1',
      externalCreditId: '',
      accountNumber: ''
    }]
  }

  private setFees(r: IBorgoApplicationResult): void {
    if (r.complementaryInfo?.costs) {
      r.complementaryInfo.costs.forEach(c => {
        // When you do just about anything to avoid a test case.
        (this as any)[this.feeMap.get(c.type)] = c.amount
      })
    }
  }
}
